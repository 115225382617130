import React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Layout from '../components/layout';
import NavBar from '../components/navbar';
import Container from '../components/container';
import config from '../lib/config';

import { GetStaticProps } from 'next';
import { getPrimaryMenuItems, getSinglePage } from '../lib/api';
import { ISponsor } from '../models/sponsor';
import { IMenuItem } from '../models/menu-item';
import { H5 } from '../components/text';

interface IProps {
	sponsors: ISponsor[];
	primaryMenuItems: IMenuItem[];
}

export default function Custom404({ sponsors, primaryMenuItems }: IProps) {
	const pageTitle = `${config.siteName}: pagina niet gevonden`;
	const metaDesc = 'De pagina die je zocht lijkt niet te bestaan.';

	return (
		<Layout sponsors={sponsors} smallScreen metaTitle={pageTitle} metaDesc={metaDesc}>
			<Head>
				<title>{pageTitle}</title>
			</Head>
			<NavBar items={primaryMenuItems} />
			<Container className="py-12">
				<article>
					<h1 className="text-6xl text-center text-slate-700 uppercase relative py-8">Niets gevonden</h1>
					<div className="flex flex-col post-content container mx-auto gap-4 lg:max-w-4xl">
						<H5 className="flex justify-center text-center mt-3 mb-6">
							Helaas! De pagina lijkt niet te bestaan.
						</H5>
						<H5 className="flex justify-center text-center">
							<Link href="/" className="hover:underline">
								Terug naar de homepagina
							</Link>
						</H5>
					</div>
				</article>
			</Container>
		</Layout>
	);
}

export const getStaticProps: GetStaticProps = async () => {
	const { sponsors } = await getSinglePage();
	const primaryMenuItems = await getPrimaryMenuItems();

	return {
		props: {
			sponsors: sponsors.nodes,
			primaryMenuItems,
		},
	};
};
